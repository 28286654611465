body{
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
@media only screen and (min-width: 830px) {
    body {
        width: 100vw;
        overflow-x: hidden;
    }
    .otros_logos {
        display: inline;
        margin-left: 2rem;
    }
    .justificado {
        text-align: justify;
    }
}
#logo {
    width: 20.5vw;
    min-width: 150px;
}
#tixo_logo {
    height: 100px;
}
#expansion_logo {
    height: 45px;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.8);
}
.list-group-item.active {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    width: 100%;
    color: #495057;
    text-align: inherit;
}
.negro {
    background-color: #000;
}
