#logo{
    width: 20.5vw;
    min-width: 150px;
}
#tixo_logo{
    height: 100px;
}
#expansion_logo{
    height: 45px;
}
.navbar-light .navbar-nav .nav-link{
    color: rgba(0,0,0,0.8);
}
.list-group-item.active {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    width: 100%;
    color: #495057;
    text-align: inherit;
}
.negro{
    background-color: #000;
}
.carousel-item{
    text-align: center;
}
.imagenCarrousel{
    margin: 5px;
}
.carousel-control-next,
.carousel-control-prev {
    width: 10%;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: black;
}
@media only screen and (min-width: 830px) {
	.otros_logos{
		display:inline;
		margin-left: 2rem;
	}
	.justificado{
		text-align:justify;
    }
    .imagenCarrousel{
        width: 70%;
    }.carousel-control-next,
    .carousel-control-prev {
        width: 20%;
    }
}

